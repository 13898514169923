import React from "react";

import DashboardController, { Props } from "./DashboardController.web";
import ProductCard from "../../../components/src/productcard/index";
//@ts-ignore
import content from "../../../components/src/content.js"
import {
  IoIosArrowDropleft,
  IoIosArrowDropright,
} from "react-icons/io";
import Carousel from "react-elastic-carousel";

import { withRouter } from "react-router-dom";
import "pure-react-carousel/dist/react-carousel.es.css";
import {
  Serviceicon1,
  Serviceicon2,
  Serviceicon3,
  Serviceicon4,
} from "./assets";
import "../assets/css/index.css";
import Loader from "../../../components/src/Loader.web";
// Customizable Area Start
// Customizable Area End
class HomeDashboard extends DashboardController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  render() {
    // Customizable Area Start
    let carousel = React.createRef();
    let Breakpoints = [
      { width: 200, itemsToShow: 1, itemsToScroll: 1 },
      { width: 320, itemsToShow: 2, itemsToScroll: 1 },
      { width: 500, itemsToShow: 3, itemsToScroll: 1 },
      { width: 769, itemsToShow: 5, itemsToScroll: 2 },
      { width: 1000, itemsToShow: 6, itemsToScroll: 2 },
      { width: 1300, itemsToShow: 7, itemsToScroll: 3 },
    ];

    const banner_one = this.state.banners.length > 1 && this.state.banners[0].attributes.images.data[0].attributes.url

    return (
      <>
        {this.state.dashboardLoader && <Loader loading={this.state.dashboardLoader} />}
        <section className="dashboard-carousel-section">
          {localStorage.removeItem("newest")}
          {this.state.banners.length > 0 && this.state.banners[0].attributes.images != null && (

            <Carousel
              isRTL={false}
              itemsToShow={1}
              itemsToScroll={1}
              pagination={this.state.banners[0].attributes.images.data.length > 1 ? true : false}
              showArrows={false}
              enableAutoPlay={true}
              autoPlaySpeed={5000}
            >
              {this.state.banners.length > 0 && this.state.banners[0].attributes.images.data.map((banner: any, index: number) => {


                return <div
                  key={index}
                  onClick={() => {
                    //@ts-ignore
                    banner.attributes.url_link && window.location.replace(banner.attributes.url_link)

                  }}
                  className="w-100"
                >
                  <img src={banner.attributes.url} style={banner.attributes.url_link ? { cursor: 'pointer' } : { cursor: 'default' }} className="bg-banner"></img>
                  <div className="main-container">
                    <div className="banner-text">
                    </div>
                  </div>
                </div>
              })}
            </Carousel>
          )}
          <section className="content-part">
            <div className="main-container">
              <div className="service-steps d-none">
                <div className="step">
                  <img src={Serviceicon1} className="banner-image" />
                  <div>
                    <p>
                      <strong>{content.homeFreeDeliveryStrip.FreeDelivery}</strong>
                    </p>
                    <p>{content.homeFreeDeliveryStrip.FreeDeliveryDescription}</p>
                  </div>
                </div>
                <div className="step">
                  <img src={Serviceicon2} className="banner-image" />
                  <div>
                    <p>
                      <strong>{content.homeFreeDeliveryStrip.SecurePayment}</strong>
                    </p>
                    <p>{content.homeFreeDeliveryStrip.SecurePaymentDescription}</p>
                  </div>
                </div>
                <div className="step">
                  <img src={Serviceicon3} className="banner-image" />
                  <div>
                    <p>
                      <strong>{content.homeFreeDeliveryStrip.return}</strong>
                    </p>
                    <p>{content.homeFreeDeliveryStrip.returnDescription}</p>
                  </div>
                </div>
                <div className="step border-0">
                  <img src={Serviceicon4} className="banner-image" />
                  <div>
                    <p>
                      <strong>{content.homeFreeDeliveryStrip.support}</strong>
                    </p>
                    <p>{content.homeFreeDeliveryStrip.supportDescrip}</p>
                  </div>
                </div>
              </div>

              <section className="collections">
                <h2>{content.HomeCollection}</h2>
                <div className="carousal">
                  <button
                    style={{
                      border: "none",
                      outline: "none",
                      background: "transparent",
                      color: "#324688",
                    }}
                    className="carousel__back-button"
                  >
                    <IoIosArrowDropleft
                      className="slider-left img-fluid"
                      /*@ts-ignore */
                      onClick={() => carousel.current.slidePrev()}
                    />
                  </button>
                  <button
                    style={{
                      border: "none",
                      outline: "none",
                      background: "transparent",
                      color: "#324688",
                    }}
                    className="carousel__next-button"
                  >

                    <IoIosArrowDropright
                      className="slider-right img-fluid"
                      /* @ts-ignore */
                      onClick={() => carousel.current.slideNext()}
                    />
                  </button>

                  <Carousel
                    isRTL={false}
                    pagination={false}
                    showArrows={false}
                    //@ts-ignore
                    ref={carousel}
                    breakPoints={Breakpoints}
                  >
                    {this.state.collectionCategory &&
                      this.state.collectionCategory.map(
                        (category: any, index: any) => {
                          return (
                            <div key={index}>
                              <img
                                className="card-img-top"
                                src={category.attributes.product_image.url}
                                alt="Card image cap"
                                style={{ borderRadius: "50%" }}
                                onClick={() => {
                                  localStorage.setItem("category", category.id);
                                  //@ts-ignore
                                  this.props?.history?.push(`./Filteroptions?&page=${1}&per_page=${15}&sort[order_by]=&sort[direction]=&q[category_id][]=${category.id}`);
                                }}
                              />
                              <div className="card-body">
                                <p className="card-text">
                                  {category.attributes.name}
                                </p>
                              </div>
                            </div>
                          );
                        }
                      )}
                  </Carousel>
                </div>
              </section>

              <section className="new-collection">
                <div className="box-carousal">
                  {this.state.newCollection && (
                    <ProductCard
                      collection={this.state.newCollection}
                      name="New Collection"
                      onViewMore={() => {
                        localStorage.setItem("newest", "By Newest")
                        //@ts-ignore
                        this.props?.history?.push(`./Filteroptions?&page=${1}&per_page=${15}&sort[order_by]=created_at&sort[direction]=desc`);

                      }}
                      addToCart={this.addToCart}
                      createWishlist={this.postWishlist}
                      deleteWishlist={this.delWishlist}
                      toSetDefaultVariant={this.toSetDefaultVariant}
                    />
                  )}
                </div>
              </section>
            </div>
            <div className="container-flex">
              {this.state.banners.length > 0 && this.state.bannerPosition2 && this.state.bannerPosition2.attributes.images != null && (
                <div className="offer-banner-wrap">
                  <div className="offer-banner">
                    <div className="banner-text">
                      <div className="add-text add1">
                      </div>
                      <img
                        src={
                          this.state.bannerPosition2.attributes.images.data[0]
                            .attributes.url
                        }
                        style={this.state.bannerPosition2.attributes.images.data[0].attributes.url_link ? { cursor: 'pointer' } : { cursor: 'default' }}
                        alt="Card image cap"
                        onClick={() => {
                          //@ts-ignore
                          this.state.bannerPosition2.attributes.images.data[0].attributes.url_link && window.location.replace(this.state.bannerPosition2.attributes.images.data[0].attributes.url_link)

                        }}
                      />
                    </div>
                    {this.state.bannerPosition2.attributes.images.data.length > 1 && <div className="banner-text">
                      <div className="add-text add2">
                      </div>
                      <img
                        src={
                          this.state.bannerPosition2.attributes.images.data[1]
                            .attributes.url
                        }
                        style={this.state.bannerPosition2.attributes.images.data[1].attributes.url_link ? { cursor: 'pointer' } : { cursor: 'default' }}
                        alt="Card image cap"
                        onClick={() => {
                          //@ts-ignore
                          this.state.bannerPosition2.attributes.images.data[1].attributes.url_link && window.location.replace(this.state.bannerPosition2.attributes.images.data[1].attributes.url_link)

                        }}
                      />
                    </div>
                    }
                  </div>
                  <div className="full-size offer-banner">
                    {this.state.bannerPosition2.attributes.images.data.length > 2 && <div className="banner-text">
                      <div className="add-text add3">
                      </div>
                      <img
                        src={
                          this.state.bannerPosition2.attributes.images.data[2]
                            .attributes.url
                        }
                        style={this.state.bannerPosition2.attributes.images.data[2].attributes.url_link ? { cursor: 'pointer' } : { cursor: 'default' }}
                        alt="Card image cap"
                        onClick={() => {
                          //@ts-ignore
                          this.state.bannerPosition2.attributes.images.data[2].attributes.url_link && window.location.replace(this.state.bannerPosition2.attributes.images.data[2].attributes.url_link)

                        }}
                      />
                    </div>

                    } </div>
                </div>
              )}
            </div>
            <section className="three-boxes">
              {this.state.banners.length > 0 && this.state.bannerPosition3 && this.state.bannerPosition3.attributes.images != null &&
                (
                  <div className="main-container">
                    <div className="shop-card-wrap">
                      <div className="shop-card"
                        style={this.state.bannerPosition3.attributes.images.data[0].attributes.url_link ? { cursor: 'pointer' } : { cursor: 'default' }}
                        onClick={() => {
                          //@ts-ignore                       
                          this.state.bannerPosition3.attributes.images.data[0].attributes.url_link && window.location.replace(this.state.bannerPosition3.attributes.images.data[0].attributes.url_link)

                        }}>
                        <img
                          src={
                            this.state.bannerPosition3.attributes.images.data[0]
                              .attributes.url
                          }
                          alt=""

                        />
                        <div className="card-text">
                        </div>
                      </div>
                      {this.state.bannerPosition3.attributes.images.data.length > 1 && <div className="shop-card middle-card"
                        style={this.state.bannerPosition3.attributes.images.data[1].attributes.url_link ? { cursor: 'pointer' } : { cursor: 'default' }}
                        onClick={() => {
                          //@ts-ignore
                          this.state.bannerPosition3.attributes.images.data[1].attributes.url_link && window.location.replace(this.state.bannerPosition3.attributes.images.data[1].attributes.url_link)

                        }}>
                        <img
                          src={
                            this.state.bannerPosition3.attributes.images.data[1]
                              .attributes.url
                          }
                          alt=""
                        />
                        <div className="card-text">
                        </div>
                      </div>}
                      {this.state.bannerPosition3.attributes.images.data.length > 2 &&
                        <div className="shop-card"
                          style={this.state.bannerPosition3.attributes.images.data[2].attributes.url_link ? { cursor: 'pointer' } : { cursor: 'default' }}
                          onClick={() => {
                            //@ts-ignore
                            this.state.bannerPosition3.attributes.images.data[2].attributes.url_link && window.location.replace(this.state.bannerPosition3.attributes.images.data[2].attributes.url_link)

                          }}>
                          <img src={this.state.bannerPosition3.attributes.images.data[2].attributes.url} alt="" />
                          <div className="card-text">
                            {/* <p>Product Name</p> */}
                            {/* <button className="primary-btn">Shop Now</button> */}
                          </div>

                        </div>}
                    </div>
                  </div>
                )}
            </section>
            <section className="new-collection">
              <div className="main-container">
                <div className="box-carousal">
                  {this.state.featuredProduct && (
                    <ProductCard
                      collection={this.state.featuredProduct}
                      name="Recommended Products"
                      onViewMore={() => {
                        localStorage.setItem("newest", "Recommended")
                        //@ts-ignore
                        this.props?.history?.push(`./Filteroptions?&page=${1}&per_page=${15}&sort[order_by]=recommended&sort[direction]=desc`);

                      }}
                      addToCart={this.addToCart}
                      createWishlist={this.postWishlist}
                      deleteWishlist={this.delWishlist}
                      toSetDefaultVariant={this.toSetDefaultVariant}
                    />
                  )}
                </div>
              </div>
            </section>
            <div className="container-flex">
              {this.state.banners.length > 0 && (
                <div className="offer-banner-wrap2">
                  {this.state.bannerPosition4 && this.state.bannerPosition4.attributes.images != null && <div className="offer-banner">
                    <div className="banner-text">
                      <div className="add-text pos1">
                      </div>
                      <img
                        src={
                          this.state.bannerPosition4.attributes.images.data[0]
                            .attributes.url
                        }
                        alt="Card image cap"
                        style={this.state.bannerPosition4.attributes.images.data[0].attributes.url_link ? { cursor: 'pointer' } : { cursor: 'default' }}
                        onClick={() => {
                          //@ts-ignore
                          this.state.bannerPosition4.attributes.images.data[0].attributes.url_link && window.location.replace(this.state.bannerPosition4.attributes.images.data[0].attributes.url_link)

                        }}
                      />
                    </div>
                    {this.state?.bannerPosition4.attributes.images?.data.length > 1 && <div className="banner-text">
                      <div className="add-text pos2">
                      </div>
                      <img
                        src={
                          this.state?.bannerPosition4.attributes.images?.data[1]
                            .attributes.url
                        }
                        alt="Card image cap"
                        style={this.state.bannerPosition4.attributes.images.data[1].attributes.url_link ? { cursor: 'pointer' } : { cursor: 'default' }}
                        onClick={() => {
                          //@ts-ignore
                          this.state.bannerPosition4.attributes.images.data[1].attributes.url_link && window.location.replace(this.state.bannerPosition4.attributes.images.data[1].attributes.url_link)

                        }}
                      />
                    </div>
                    }
                  </div>
                  }
                  <div className="full-size-banner offer-banner">
                    {this.state.banners.length > 0 && this.state.bannerPosition5 && this.state.bannerPosition5.attributes.images != null && <div className="banner-text">
                      <div className="add-text pos3">
                      </div>
                      <img
                        src={
                          this.state.bannerPosition5.attributes.images.data[0]
                            .attributes.url
                        }
                        style={this.state.bannerPosition5.attributes.images.data[0].attributes.url_link ? { cursor: 'pointer' } : { cursor: 'default' }}
                        alt="Card image cap"
                        onClick={() => {
                          //@ts-ignore
                          this.state.bannerPosition5.attributes.images.data[0].attributes.url_link && window.location.replace(this.state.bannerPosition5.attributes.images.data[0].attributes.url_link)

                        }}
                      />
                    </div>}
                  </div>
                </div>
              )}
            </div>
          </section>
        </section>
      </>
    );
    // Customizable Area End
  }
  // Customizable Area Start
  // Customizable Area End
}
//@ts-ignore
export default withRouter(HomeDashboard);
export { HomeDashboard }
